import React from 'react'
import tw, { styled } from 'twin.macro'

import { MainLayout } from '@common/layouts'
import { SEO } from '@components'

const Content = styled.div`
  ${tw`prose prose mx-auto max-w-screen-xl text-justify leading-6 antialiased py-16 px-6 text-gray-800`}
`

const TermsOfUsePage = ({ siteUrl }) => {
  return (
    <MainLayout>
      <Content>
        <SEO title="Terms of Use" />

        Note:
        <i>
          HelloMD’s Master Terms of Use, Privacy Policy and Medical Terms of Service
          are collectively referred to ’HelloMD’s Terms of Service’.
        </i>
        <h1>HelloMD Master Terms of Use</h1>
        <div class="mb-4">Last modified: July 1, 2021</div>
        <p>
          Welcome to HelloMD. The following are the Master terms of use ("Terms of
          Use") that, together with our <a
            href="/medical-terms-of-service"
          >Medical Referral Terms and Conditions</a> and <a
            href="/privacy-policy"
          >Privacy Policy</a> (which are incorporated into these Terms of Use by
          reference), govern the use of our site by users. Please review these
          carefully. Especially note that any potential claim is required to be
          resolved by binding arbitration as described below instead of suing in
          court. We may update these Terms of Use, at our sole discretion, from time
          to time by notifying you of such changes by any reasonable means, including
          by posting a revised Terms of Use through the HelloMD Sites [the "Last
          Updated" above indicates the last time these Terms of Use were updated]. Any
          such changes will not apply to any dispute between you and us arising prior
          to the date on which we posted the revised Terms of Use incorporating such
          changes or otherwise notified you of such changes. By continuing to use or
          access any of the HelloMD Sites after we post any changes, you accept the
          updated Terms of Use.
        </p>
        <h2>Introduction</h2>
        <p>
          By using our HelloMD Sites, you affirm that you are of legal age to enter
          into these Terms of Use or, if you are not, that you have obtained parental
          or guardian consent to enter into these Terms of Use. In any case, at a
          minimum you must be at least 18 years old. If you violate or do not agree to
          these Terms of Use, then your access to and use of the HelloMD Sites is
          unauthorized.
        </p>
        <p class="font-semibold">
          Definitions: The following terms are used from time to time and have the
          following meaning:
        </p>
        <ol>
          <li>
            <strong>"Content,"</strong>
            means merchandise information, product descriptions, reviews, comments,
            messages, reviews, communications, feedback, submissions, suggestions,
            questions, and other information, data, content, and materials (including
            page headers, images, text, illustrations, formats, logos, hashtags,
            designs, icons, photographs, software programs, music clips or downloads,
            video clips, and written and other materials.)
          </li>
          <li>
            <strong>"HelloMD,"</strong>
            means HelloMD Corporation (dba "HelloMD") (including any subsidiaries that
            HelloMD Corporation may form or acquire in the future). We also refer to
            HelloMD as "we," "us" and "our."
          </li>
          <li>
            <strong>"HelloMD Entities,"</strong>
            means HelloMD and its affiliates; its and their suppliers, vendors,
            contractors, and licensors; and its and their directors, officers,
            employees, and agents.
          </li>
          <li>
            <strong>"HelloMD Sites,"</strong>
            means hellomd.ca, any HelloMD Apps, and all related functionality,
            services, and Content offered by or for HelloMD on or through
            hellomd.ca and the HelloMD Apps or the systems, servers, and networks used
            to make the HelloMD Sites available.
          </li>
          <li>
            <strong>"HelloMD Apps,"</strong>
            means the official "HelloMD App" for iPhone and Android, as may become
            available for download from the iTunes App Store or the Google Play Store.
          </li>
          <li>
            <strong>"Ideas,"</strong>
            means ideas, concepts, feedback, and know-how that you make available in
            connection with the HelloMD Sites.
          </li>
          <li>
            <strong>"Intellectual Property"</strong> means copyrights, trademarks,
            trade dress, patent, copyright, know-how, trademark, or trade secret of
            HelloMD or its partners.
          </li>
          <li>
            <strong>"make available,"</strong>
            means post, transit, publish, upload, distribute, transmit, display,
            provide, or otherwise submit or make available (including through any part
            of the HelloMD Sites administrated by third-party social media platforms
            (e.g., Facebook or Instagram) that allow interaction with the HelloMD
            Sites through the tools offered by such social media platforms).
          </li>
          <li>
            <strong>"Materials,"</strong>
            means Content that HelloMD Entities make available on or through the
            HelloMD Sites.
          </li>
          <li>
            <strong>"Medical Document”</strong>
            means a document provided by a health care practitioner to support the use
            of cannabis for medical purposes, as set out in the Cannabis Regulations
            to the Cannabis Act.
          </li>
          <li>
            <strong>"you" or "your"</strong>
            means any user of any HelloMD Site.
          </li>
          <li>
            <strong>"Terms of Use,"</strong>
            means these Terms of Use and all other terms and policies posted by
            HelloMD on the HelloMD Sites (and any updates by HelloMD to these Terms of
            Use and those terms and policies).
          </li>
        </ol>
        <br />
        <p class="font-semibold">
          IMPORTANT: THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT
          REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES.
          THIS MEANS THAT YOU AND HELLOMD ARE EACH GIVING UP OUR RIGHTS TO SUE EACH
          OTHER IN COURT OR IN CLASS ACTIONS OF ANY KIND.
        </p>
        <h2>Registration/User Names</h2>
        <p>
          You are not obligated to register with HelloMD in order to access the
          HelloMD Sites. However, certain services of the HelloMD Sites are available
          only to users who have registered with HelloMD or other persons affiliated
          with HelloMD who have been granted accounts with usernames and passwords
          ("Secure Users"). If you are a Secure User, you agree to accurately maintain
          and update any information about yourself that you have provided to HelloMD.
          If you do not maintain such information, or HelloMD has reasonable grounds
          to suspect as much, HelloMD has the right to suspend or terminate your
          account and your use of the HelloMD Sites. You may have as many active
          Secure User accounts on the HelloMD Sites as you choose at any given time,
          and only you may use your designated accounts to access the HelloMD Sites,
          and you may not use anyone else's account at any time. With the exception of
          parents and legal guardians acting on behalf of minor patients in accordance
          with the Cannabis Regulations to the Cannabis Act, you may not make any
          request for a Medical Document on behalf of any other individual with a
          HelloMD account or otherwise at any time. You also agree to promptly notify
          HelloMD of any unauthorized use of your username, password or any other
          breach of security that you become aware of involving or relating to the
          HelloMD Sites by emailing HelloMD at <a
            href="mailto:support@hellomd.com"
          >support@hellomd.com</a>. In addition, you agree to exit from your Secure
          User account at the end of each session. HelloMD explicitly disclaims
          liability for any and all losses and damages arising from your failure to
          comply with this section.
        </p>
        <p>
          Our HelloMD Sites requires users to give us unique identifiers in order to
          log into many areas of the HelloMD Sites. We utilize these unique
          identifiers to verify the user's identity and eligibility, in order to
          protect our members from the release of sensitive or personally identifiable
          information to unauthorized users. To help protect the privacy of data you
          transmit through the HelloMD Sites, where personally identifiable
          information is requested, we also use technology designed to encrypt the
          information that you input before it is sent to us using Secure Sockets
          Layer (SSL) technology or similar encryption technology. In addition,
          HelloMD takes steps to protect the user data we collect against unauthorized
          access. However, you should keep in mind that the HelloMD Sites and our
          services are run on software, hardware, and networks, any component of which
          may, from time to time, require maintenance or experience problems or
          breaches of security beyond our control.
        </p>
        <p>
          You must exercise caution, good sense, and sound judgment in using the
          HelloMD Sites. You are prohibited from violating, or attempting to violate,
          the security of the HelloMD Sites. Any such violations may result in
          criminal and/or civil penalties against you. HelloMD may investigate any
          alleged or suspected violations and if a criminal violation is suspected
          whether in regards to the HelloMD Sites, your Content or your procurement of
          a Medical Document, HelloMD will cooperate with law enforcement agencies in
          their investigations or any court order, including any legal order
          requesting or directing us to disclose the identity or other information of
          anyone posting any materials on or through the Site.
        </p>
        <h2>HelloMD Monitoring of Site</h2>
        <p>
          HelloMD will have the right (but not the obligation), in our sole
          discretion, to monitor, evaluate, and analyze Content, and any use of and
          access to the HelloMD Sites, including to determine compliance with these
          Terms of Use and any other operating rules that may be established by
          HelloMD from time to time. HelloMD will also have the right (but not the
          obligation), in our sole discretion, to edit, move, delete, or refuse to
          make available any Content made available through, the HelloMD Sites, for
          any reason, including violation of these Terms of Use, whether for legal or
          other reasons. Despite this right of ours, you are solely responsible for
          any Content you make available, and you agree to indemnify HelloMD for all
          claims resulting from any Content you make available.
        </p>
        <h2>Your Use of the Sites</h2>
        <p>
          You certify to HelloMD that the Content you provide on or through any of the
          HelloMD Sites is accurate and that the information you provide on or through
          the HelloMD Sites is complete. You are responsible for maintaining the
          confidentiality and security of your account including username & password.
        </p>
        <p>
          HelloMD is not responsible for any losses arising out of the unauthorized
          use of your account. You agree that HelloMD does not have any responsibility
          if you lose or share access to your device.
        </p>
        <p>
          Your account may be restricted or terminated for any reason, at our sole
          discretion. Except as otherwise provided by law, at any time without notice
          to you, we may (1) change, restrict access to, suspend, or discontinue the
          HelloMD Sites or any portion of the HelloMD Sites, and (2) charge, modify,
          or waive any fees required to use any services, functionality or other
          content available through the HelloMD Sites or any portion of the HelloMD
          Sites.
        </p>
        <p>In connection with the HelloMD Sites, you will not:</p>
        <ol>
          <li>
            (a)Make available any Content through or in connection with the HelloMD
            Sites that is or may be in violation of the content guidelines set forth
            below.
          </li>
          <li>
            (b) Make available through or in connection with the HelloMD Sites any
            virus, worm, Trojan horse, Easter egg, time bomb, spyware, or other
            computer code, file or program that is or is potentially harmful or
            invasive or intended to damage or hijack the operation of, or to monitor
            the use of, any hardware, software, or equipment.
          </li>
          <li>
            (c)Use the HelloMD Sites for any commercial purpose, or for any purpose
            that is fraudulent or otherwise tortious or unlawful.
          </li>
          <li>(d)Harvest or collect information about users of the HelloMD Sites.</li>
          <li>
            (e)Interfere with or disrupt the operation of the HelloMD Sites or the
            systems, servers, or networks used to make the HelloMD Sites available,
            including by hacking or defacing any portion of the HelloMD Sites; or
            violate any requirement, procedure or policy of such servers or networks.
          </li>
          <li>
            (f)Restrict or inhibit any other person from using the HelloMD Sites.
          </li>
          <li>
            (g)Reproduce, modify, adapt, translate, create derivative works of, sell,
            rent, lease, loan, timeshare, distribute, or otherwise exploit any portion
            of (or any use of) the HelloMD Sites except as expressly authorized in
            these Terms of Use, without HelloMD's express prior written consent.
          </li>
          <li>
            (h)Reverse engineer, decompile, or disassemble any portion of the HelloMD
            Sites, except where such restriction is expressly prohibited by applicable
            law.
          </li>
          <li>
            (i)Remove any copyright, trademark, or other proprietary rights notice
            from the HelloMD Sites.
          </li>
          <li>
            (j)Frame or mirror any portion of the HelloMD Sites, or otherwise
            incorporate any portion of the HelloMD Sites into any product or service,
            unless you obtain HelloMD's express prior written consent to do so.
          </li>
          <li>(k) Systematically download and store any Materials.</li>
          <li>
            (l)Use any robot, spider, site search/retrieval application or other
            manual or automatic device to retrieve, index, "scrape," "data mine" or
            otherwise gather any Materials, or reproduce or circumvent the
            navigational structure or presentation of the HelloMD Sites, without
            HelloMD's express prior written consent.
          </li>
          <li>(m)Cause injury to any person or entity.</li>
          <li>(n)Violate any law, rule, or regulation, or these Terms of Use.</li>
          <li>
            (o)You will not use the HelloMD Sites or HelloMD's name, logo, or brand to
            (1) send any unsolicited or unauthorized Content, including advertising,
            promotional materials, email, junk mail, spam, or other form of
            solicitation; or (2) use any meta tags or other hidden text or metadata
            utilizing a HelloMD trademark, logo, URL, or product name without
            HelloMD's written consent; or
          </li>
          <li>
            (p)You will not attempt to do anything, or permit, encourage, assist, or
            allow any third party to do anything, prohibited in this Section, or
            attempt, permit, encourage, assist, or allow any other violation of these
            Terms of Use.
          </li>
        </ol>
        <h2>Privacy Policy</h2>
        <p>
          All information we collect on the HelloMD Sites is subject to our <a
            href="/privacy-policy"
          >Privacy Policy</a>. By using the HelloMD Sites, you consent to all actions
          taken by us with respect to your information in compliance with the
          <a href="/privacy-policy">Privacy Policy</a>
          and the
          <a href="/medical-terms-of-service">Medical Cannabis Terms of
            Use</a>.
        </p>

        <h2>Contributions</h2>
        <p>
          HelloMD may provide functionality that enables users to make available
          Content and Ideas in connection with the HelloMD Sites. At this time,
          HelloMD is only available in the English language, so we are only accepting
          Content that is written in English. You acknowledge and agree that you are
          responsible for all Content and Ideas you submit or otherwise make available
          on any of the HelloMD Sites. You agree that any Content and Ideas that you
          make available, and your use of such Content and Ideas, will not violate any
          term of these Terms of Use. You are solely responsible for the legality,
          accuracy, appropriateness, originality, and your rights in any such Content
          and Ideas.
        </p>
        <p>
          You hereby represent that you have the full authority to grant the right to
          HelloMD as set forth herein. You hereby grant to HelloMD a royalty-free,
          perpetual, irrevocable, worldwide, unlimited, nonexclusive license to use,
          reproduce, create derivative works from, modify, publish, edit, translate,
          distribute, perform and display (publicly or otherwise) any Content that you
          make available, in any media or medium, and in any form, format, or forum
          now known or hereafter developed. You further agree that HelloMD is free to
          use any Ideas for any purpose. HelloMD may sublicense its rights in Content
          and Ideas through multiple tiers of sublicenses. HelloMD is, and will be,
          under no obligation (1) to maintain any Content or Idea in confidence; (2)
          to pay any compensation for any Content or Idea; or (3) to respond to any
          Content or Idea. You grant to HelloMD the right to use any name associated
          with any Content or Idea that you make available to HelloMD, although
          HelloMD has no obligation to exercise such right, or to otherwise provide
          any attribution for any Content or Idea.
        </p>
        <h2>Prohibited Content</h2>
        <p>
          You agree that you will not make available Content in connection with the
          HelloMD Sites that: (a) is false, fraudulent, inaccurate, or misleading; (b)
          is intended to cause harm, damage, disable, or otherwise interfere with the
          HelloMD Sites (c) violates any local, provincial, federal, or international
          laws or is otherwise tortious; (d) is protected by or would infringe on the
          rights of others (including HelloMD), including any Intellectual Property,
          right of publicity or privacy, or any other proprietary right, without the
          express prior written consent of the applicable owner; (e) is obscene,
          indecent, pornographic, or otherwise objectionable or is derogatory,
          defamatory, threatening, harassing, abusive, slanderous, hateful, or
          embarrassing to any other person or entity as determined by HelloMD in its
          sole discretion; (f) victimizes, harasses, degrades, or intimidates or is
          violent or threatening; (g) promotes violence against, or actions that are
          threatening to, any individual or group, including without limiting the
          foregoing, on the basis of religion, gender, sexual orientation, race,
          ethnicity, age or disability; (i) contains advertisements, solicitations, or
          spam links to other web sites or individuals; (j) impersonates another
          business, person, or entity, including HelloMD, its related entities,
          employees, and agents; (k) violates any policy posted on the HelloMD Sites;
          or (l) contains your full name(s), or any other confidential personally
          identifiable information of yourself or others.
        </p>
        <p>
          Without limiting the above, you hereby agree that you will not make any
          statements or claims which violate the Food and Drugs Act or any other
          applicable laws or regulations. You hereby agree and consent that HelloMD
          may remove any Content that HelloMD, in its sole discretion, determines to
          be in violation of this prohibition.
        </p>
        <h2>Reporting & Reviewing Prohibited Content</h2>
        <p>
          If you believe that any Content on the HelloMD Services contains prohibited
          Content, please contact <a
            href="mailto:support@hellomd.com"
          >support@hellomd.com</a>
        </p>
        <p>
          HelloMD takes the health and wellbeing of its users extremely seriously and
          will review all reports of Prohibited Content. If HelloMD determines that
          the Content is Prohibited by these Terms, or for any other reason, in its
          sole discretion, HelloMD reserves the right to take any action HelloMD deems
          necessary and appropriate, which may include but is not limited to: (i)
          immediately and permanently removing the content; (ii) suspending the user
          who contributed the Content; or (iii) permanently banning the user from
          HelloMD's services.
        </p>
        <h2>Information and Materials</h2>
        <p>
          HelloMD and its suppliers and licensors may make available to users various
          Materials. The Materials are for educational and informational purposes
          only, and errors may appear from time to time. Before you act in reliance on
          any Materials, you should confirm any facts that are important to your
          decision. The HelloMD Entities make no warranty as to the reliability,
          accuracy, timeliness, usefulness, or completeness of any Materials.
        </p>
        <p>
          HelloMD assumes no responsibility for the improper use of, and
          self-diagnosis, and/or treatment using any products listed on our Sites. Any
          products shown on our sites should not be confused with prescription
          medicine. Any products on our site should not be used as a substitute for
          medically supervised therapy. If you suspect you suffer from clinical
          deficiencies, consult a licensed, qualified health care practitioner.
        </p>
        <p>
          It is important that you understand why no claims can be made about the
          products displayed on the HelloMD Sites and that we cannot suggest the use
          of any product that will effect a cure or affect a symptom/ailment. Before
          starting any product for illness, it is always wise to check with a medical
          doctor. It is especially important for people who are: pregnant or breast
          feeding, chronically ill, elderly, under 18, and currently taking
          prescription or over-the-counter medicines. None of the products or
          information available to the public are intended to be a treatment protocol
          for any disease state, but rather are offered to provide information and
          choices regarding nutritional support for various health concerns. None of
          the information presented is intended to be an enticement to purchase and
          should not be construed as medical advice or instruction.
        </p>
        <p>
          No action should be taken solely on information contained on the HelloMD
          Sites, regardless of perceived scientific merit. Instead, readers should
          consult health care practitioner on any matter related to their health. The
          information obtained from referenced materials shown on the HelloMD Sites is
          believed to be accurate, as presented by their respective authors. HelloMD
          assumes no liability for any personal interpretation. Users who fail to
          consult their physicians prior to the purchase and subsequent use of any
          product assume the risk of any adverse effects.
        </p>
        <p>
          The use of any nutritional supplement or Cannabis product for any reason is
          neither implied nor advocated by HelloMD.
        </p>
        <p>
          For any suspected or known illness or health concern, always consult with
          your physician or health care provider prior to the purchase or use of any
          nutritional product. HelloMD has attempted to present information from
          literature, books, and other references, as well as information about
          products as accurately as possible, but you should be aware that Health
          Canada has not approved or evaluated the information contained in that
          literature or on this site. Any literature reference or direct link to a
          specific product is for your information or convenience only and should not
          be construed as an enticement to purchase, and further is not intended or
          implied to be used in the mitigation, diagnosis, treatment, cure, or
          prevention of any disease or illness.
        </p>
        <p>
          Information on this site is provided for informational purposes only and is
          not meant as a substitute for the advice provided by your own physician or
          other trained medical professionals. The results reported may not
          necessarily occur in all individuals.
        </p>
        <p>
          THE HELLOMD ENTITIES ASSUME NO RESPONSIBILITY FOR ANY CONSEQUENCE RELATING
          DIRECTLY OR INDIRECTLY TO ANY ACTION OR INACTION YOU TAKE BASED ON THE
          MATERIALS.
        </p>
        <h2>Third Party Products</h2>
        <p>
          The HelloMD Sites may make available listings, descriptions, and images of
          goods and services (collectively,
          <strong>"Products"</strong>). We make no representations as to the
          completeness, accuracy, reliability, validity, or timeliness of such
          listings, descriptions, or images (including any features, specifications,
          and prices contained on the Sites). HelloMD does not review, evaluate, or
          check any listings, descriptions, or images in advance for accuracy. Such
          information is subject to change at any time without notice.
        </p>
        <p>
          We have made efforts to accurately display the attributes of Products,
          including the applicable colors. However, as the actual colors you see will
          depend on your monitor, we cannot guarantee that your monitor's display of
          any color will accurately reflect actual product color or finish. In
          addition, certain weights, measures, and similar descriptions are
          approximate and are for convenience only.
        </p>
        <p>
          HelloMD has no liability to you for content on the HelloMD Sites that you
          find to be offensive, indecent, or objectionable.
        </p>
        <p>
          HelloMD is not responsible for, and cannot guarantee the performance of,
          goods and services provided by third parties. While our goal is to provide
          accurate information, product packaging and material may contain more and/or
          different information than that provided on HelloMD Sites, including the
          product description, country of origin, nutrition, ingredient, allergen, and
          other information. Always read labels, warnings, directions, and other
          information provided with the product before using or consuming the product.
          For additional information about a product, please contact the manufacturer.
          It is your responsibility to ascertain and obey all applicable local,
          provincial, federal, and foreign laws (including minimum age requirements)
          regarding the purchase, possession, and use of any Product.
        </p>
        <p>
          References on HelloMD Sites to any names, marks, products, or services of
          third parties, or links to third-party sites or information, are not an
          endorsement, sponsorship, or recommendation of the third party or its
          information, products, or services. HelloMD is not responsible for the
          content of any third-party linked site or any link contained in a linked
          site, including any third-party social media or mobile app platform with
          which the HelloMD Sites operate or otherwise interact, nor is HelloMD
          responsible for the acts or omissions of any operator of any such site or
          platform. Your use of any such third-party site or platform is at your own
          risk, and will be governed by such third party's terms and policies
          (including its privacy policy).
        </p>
        <p>
          If you believe that any Product(s) on the HelloMD Sites, or any statements,
          descriptions, or entries related to those Product(s), are inaccurate,
          incomplete, unreliable, offensive, discriminatory, or otherwise violate
          these Terms, please contact
          <a href="mailto:content@hellomd.com">content@hellomd.com</a>.
        </p>
        <p>
          In its sole discretion, HelloMD reserves the right to remove any Products,
          product descriptions, advertising materials, images, or any other Content
          that it deems is in violation of these Terms or any provincial or federal
          law or that it deems should be removed for any other reason.
        </p>
        <h2>Links to and from Third-Party Sites and Social Media</h2>
        <p>
          As you view the HelloMD Sites, you may see links to third-party websites.
          These links are for convenience only. If you use these links, you will leave
          the HelloMD Site. Certain of these linked websites may make use of HelloMD's
          proprietary intellectual property rights (such as copyrights, trademarks,
          service marks, logos and trade names) under licence from the HelloMD.
          HelloMD is not responsible for the availability or content, products,
          services or other materials of these other websites or for any viruses or
          other damaging elements encountered in linking to a third-party website. In
          addition, providing links to these websites should not be interpreted as
          endorsement or approval by HelloMD of the organizations sponsoring such
          third-party websites or their products or services. HelloMD shall not be
          responsible or liable, directly or indirectly, for any damages or losses
          caused or alleged to be caused by or in connection with the use of or
          reliance on such content, products, services or other materials available on
          or through any such website. HelloMD is not responsible for the privacy
          practices of any other websites. These Terms of Use do not apply to any
          other websites.
        </p>
        <p>
          You may link to the homepage of www.hellomd.ca provided you do so in a way
          that is fair and legal and does not damage our reputation or take advantage
          of it, but you must not establish a link in such a way as to suggest any
          form of association, approval, or endorsement on our part without our
          express written consent.
        </p>
        <p>
          This HelloMD Sites may provide certain social media features that enable you
          to:
        </p>
        <ul>
          <li>
            Link from your own or certain third-party websites to certain content on
            the HelloMD Site
          </li>
          <li>
            Send emails or other communications with certain content, or links to
            certain content, on this HelloMD Site.
          </li>
          <li>
            Cause limited portions of content on the HelloMD Sites to be displayed or
            appear to be displayed on your own or certain third-party websites.
            <br />
            You may use these features solely as they are provided by us, and solely
            with respect to the content they are displayed with and otherwise in
            accordance with any additional terms and conditions we provide with
            respect to such features. You agree to cooperate with us in causing any
            unauthorized framing or linking immediately to stop. We reserve the right
            to withdraw linking permission without notice. We may disable all or any
            social media features and any links at any time without notice in our
            discretion.
          </li>
        </ul>
        <h2>Third-Party Interactions</h2>
        <p>
          Your interactions with entities or individuals found on or through the Site,
          and any other terms, conditions, warranties or representations associated
          with such dealings, are solely between you and such entities or individuals.
          You should make whatever investigation you feel necessary or appropriate
          before proceeding with any online or offline transaction with any third
          party. You are solely responsible for, and will exercise caution,
          discretion, common sense and judgment in, using the Site and disclosing
          personal information.
        </p>
        <p>
          You agree that HelloMD shall not be responsible or liable for any loss or
          damage or resulting civil or criminal legal or administrative action(s) of
          any sort incurred as the result of any such dealings. If there is a dispute
          between participants on the HelloMD Sites, or between users and any third
          party, you understand and agree that HelloMD is under no obligation to
          become involved. In the event that you have a dispute with one or more other
          users, you hereby release HelloMD, its officers, employees, agents and
          successors in interest from claims, demand and damages (actual or
          consequential) of every kind or nature, known or unknown, suspected and
          unsuspected, disclosed or undisclosed, arising out of or in any way related
          to such disputes or the Site or the features and services therein.
        </p>
        <h2>Intellectual Property</h2>
        <p>
          The HelloMD Sites and all Materials, and all Intellectual Property are owned
          or controlled by or licensed to HelloMD, and are protected by Canadian and
          international trademark, copyright, and other intellectual property laws.
          Materials are licensed (not sold) to end users. Subject to your compliance
          with these Terms of Use, and solely for so long as you are permitted by
          HelloMD to use the HelloMD Sites, you may access, view, download, and print
          the Materials for your personal, non-commercial use only; provided, however,
          that you (1) retain all copyright, trademark, or other proprietary
          designations contained on all Materials; (2) do not modify or alter the
          Materials in any way; and (3) do not provide or make available the Materials
          to any third party in a commercial manner.
        </p>
        <p>
          No licence, right, title, or interest in the HelloMD Sites or any Materials
          is transferred to you as a result of your use of the HelloMD Sites or your
          accessing, viewing, downloading, or printing of the Materials. You may not
          reproduce (except as noted above), publish, transmit, distribute, display,
          modify, create derivative works from, sell, or participate in any sale of or
          exploit in any way, in whole or in part, any of the Materials or the HelloMD
          Sites. The HelloMD Sites and Materials may be used only as a personal
          shopping resource. Any other use, including the reproduction, modification,
          distribution, transmission, republication, display, or performance, of the
          HelloMD Sites and the Materials is strictly prohibited. The compilation
          (meaning the collection, arrangement, and assembly) of the HelloMD Sites and
          Materials is the exclusive property of HelloMD and is also protected by
          Canadian and international copyright laws.
        </p>
        <p>
          HELLOMD, names and logos, and all other graphics, logos, page headers,
          button icons, scripts, and service names included in or made available
          through any of the HelloMD Sites are trademarks or trade dress of HelloMD in
          Canada and other countries. All other marks are the property of their
          respective companies.
        </p>
        <p>
          The HelloMD Sites may include certain third-party technologies and open
          source materials (collectively,
          <strong>"Third-Party Technology"</strong>). Your use of such Third-Party
          Technology is subject to these Terms of Use, as well as well the applicable
          terms and conditions of such third parties.
        </p>
        <h2>Disclaimer</h2>
        <p>
          THE HELLOMD SITES, AND ALL CONTENT, MATERIALS, PRODUCTS, SERVICES,
          FUNCTIONALITY, AND OTHER ITEMS INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
          YOU THROUGH THE HELLOMD SITES, ARE PROVIDED BY HELLOMD ON AN "AS IS" AND "AS
          AVAILABLE" BASIS. NO HELLOMD ENTITY MAKES ANY REPRESENTATIONS OR WARRANTIES
          OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE HELLOMD SITES OR
          THE CONTENT, MATERIALS, PRODUCTS, SERVICES, FUNCTIONALITY, OR OTHER ITEMS
          INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE HELLOMD SITES. TO
          THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE HELLOMD ENTITIES
          DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE
          FOREGOING, THE HELLOMD ENTITIES DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR
          IMPLIED, FOR ANY MERCHANDISE DISPLAYED ON THE HELLOMD SITES. YOU ACKNOWLEDGE
          THAT, TO THE FULLEST EXTENT PROVIDED BY APPLICABLE LAW, YOUR USE OF THE
          HELLOMD SITES IS AT YOUR SOLE RISK. THIS DISCLAIMER CONSTITUTES AN ESSENTIAL
          PART OF THESE TERMS OF USE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
          LAW, YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE HELLOMD SITES AND
          AGREE THAT ANY INFORMATION YOU SEND OR RECEIVE DURING YOUR USE OF THE
          HELLOMD SITES MAY NOT BE SECURE AND MAY BE INTERCEPTED OR OTHERWISE ACCESSED
          BY UNAUTHORIZED PARTIES. YOU AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW, NO HELLOMD ENTITY IS RESPONSIBLE FOR ANY LOSS OR DAMAGE TO
          YOUR PROPERTY OR DATA THAT RESULTS FROM ANY MATERIALS YOU ACCESS OR DOWNLOAD
          FROM THE HELLOMD SITES.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PROVIDED BY APPLICABLE
          LAW, HELLOMD WILL NOT BE LIABLE TO YOU OR TO ANY OTHER PERSON UNDER ANY
          CIRCUMSTANCES OR UNDER ANY LEGAL OR EQUITABLE THEORY, WHETHER IN TORT,
          CONTRACT, STRICT LIABILITY, OR OTHERWISE, FOR ANY INDIRECT, SPECIAL,
          INCIDENTAL, OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE ARISING OUT OF
          OR IN CONNECTION WITH THE USE OF OR INABILITY TO USE THE HELLOMD SITES, EVEN
          IF AN AUTHORIZED REPRESENTATIVE OF A HELLOMD ENTITY HAS BEEN ADVISED OF OR
          SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT
          PROVIDED BY APPLICABLE LAW, THIS DISCLAIMER APPLIES TO ANY DAMAGES OR INJURY
          ARISING FROM ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION,
          DELETION, DEFECTS, DELAY IN OPERATION OR TRANSMISSION, LOST PROFITS, LOSS OF
          GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF RESULTS, COMPUTER FAILURE
          OR MALFUNCTION, COMPUTER VIRUSES, FILE CORRUPTION, COMMUNICATION FAILURE,
          NETWORK OR SYSTEM OUTAGE, THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO,
          ALTERATION OF, LOSS OF USE OF ANY RECORD OR DATA, AND ANY OTHER TANGIBLE OR
          INTANGIBLE LOSS. SUBJECT TO THE FOREGOING, TO THE FULLEST EXTENT PROVIDED BY
          APPLICABLE LAW, NO HELLOMD ENTITY WILL BE LIABLE FOR ANY DAMAGES IN EXCESS
          OF THE FEES PAID BY YOU IN CONNECTION WITH YOUR USE OF THE HELLOMD SITES
          DURING THE SIX (6) MONTH PERIOD PRECEDING THE DATE ON WHICH THE CLAIM AROSE.
        </p>
        <p>
          YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PROVIDED
          BY APPLICABLE LAW, NO HELLOMD ENTITY WILL BE LIABLE FOR ANY DEFAMATORY,
          OFFENSIVE, OR ILLEGAL CONDUCT OF ANY OTHER USER OF THE HELLOMD SITES.
        </p>
        <h2>Indemnification</h2>
        <p>
          You agree to defend (at HelloMD's option), indemnify, and hold the HelloMD
          Entities harmless from and against any and all liabilities, claims, damages,
          costs, and expenses, including attorneys' fees and costs, arising from or
          related to your misuse of the HelloMD Sites or any breach by you of these
          Terms of Use. HelloMD reserves the right, at our expense, to assume
          exclusive defense and control of any matter otherwise subject to
          indemnification by you and, in any case, you agree to cooperate with HelloMD
          if and as requested by HelloMD in the defence and settlement of such matter.
        </p>
        <h2>Arbitration/ Governing Law</h2>
        <p>
          <strong>PLEASE READ THIS SECTION CAREFULLY.</strong>
          IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE
          AN ACTION IN COURT. Using or accessing the HelloMD Sites constitutes your
          acceptance of this Arbitration provision. Please read it carefully as it
          provides that you and HelloMD will waive any right to file a lawsuit in
          court or participate in a class action for matters within the terms of the
          Arbitration provision.
        </p>
        <p>
          EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT, ALL DISPUTES
          ARISING OUT OF OR RELATED TO THESE TERMS OF USE OR ANY ASPECT OF THE
          RELATIONSHIP BETWEEN YOU AND HELLOMD, WHETHER BASED IN CONTRACT, TORT,
          STATUTE, FRAUD, MISREPRESENTATION, OR ANY OTHER LEGAL THEORY, WILL BE
          RESOLVED THROUGH FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR
          INSTEAD OF IN A COURT BY A JUDGE OR JURY, AND YOU AGREE THAT HELLOMD AND YOU
          ARE EACH WAIVING THE RIGHT TO SUE IN COURT AND TO HAVE A TRIAL BY A JURY.
          YOU AGREE THAT ANY ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS
          ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE AGREEING TO
          GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION. The arbitration will
          be administered by Judicial Arbitration Mediation Services, Inc. ("JAMS")
          pursuant to the JAMS Streamlined Arbitration Rules & Procedures effective
          July 1, 2014 (the "JAMS Rules") and as modified by this agreement to
          arbitrate. The JAMS Rules, including instructions for bringing arbitration,
          are available on the JAMS website <a
            href="https://www.jamsadr.com/"
          >https://www.jamsadr.com/</a>.
        </p>
        <p>
          The arbitrator will conduct hearings, if any, by teleconference or
          videoconference, rather than by personal appearances, unless the arbitrator
          determines upon request by you or by us that an in-person hearing is
          appropriate. Any in-person appearances will be held at a location which is
          reasonably convenient to both parties with due consideration of their
          ability to travel and other pertinent circumstances. If the parties are
          unable to agree on a location, such determination should be made by JAMS or
          by the arbitrator. The arbitrator's decision will follow the terms of these
          Terms of Use and will be final and binding. The arbitrator will have
          authority to award temporary, interim, or permanent injunctive relief or
          relief providing for specific performance of these Terms of Use, but only to
          the extent necessary to provide relief warranted by the individual claim
          before the arbitrator. The award rendered by the arbitrator may be confirmed
          and enforced in any court having jurisdiction thereof. Notwithstanding any
          of the foregoing, nothing in these Terms of Use will preclude you from
          bringing issues to the attention of federal, provincial, or local agencies
          and, if the law allows, they can seek relief against us for you.
        </p>
        <p>
          These Terms of Use will be governed by and construed under the laws of the
          Province of Ontario and the laws of Canada applicable therein, without
          regard to conflicts of law principles
        </p>
        <h2>Termination</h2>
        <p>
          These Terms of Use are effective unless and until terminated by either you
          or HelloMD. You may terminate these Terms of Use at any time, provided that
          you discontinue any further use of the HelloMD Sites. We also may terminate
          these Terms of Use at any time and may do so immediately without notice, and
          deny you access to the HelloMD Sites, if in our sole discretion you fail to
          comply with any term or provision of these Terms of Use. Upon any
          termination of these Terms of Use by either you or HelloMD, you must
          promptly destroy all Materials and other Content downloaded or otherwise
          obtained from the HelloMD Sites, as well as all copies of such Content,
          whether made under these Terms of Use or otherwise. Upon any such
          termination, you must destroy all Content obtained from the Site and all
          copies thereof. The provisions of these Terms of Use concerning Site
          security, prohibited activities, copyrights, trademarks, user submissions,
          disclaimer, limitation of liability, indemnity and jurisdictional issues
          shall survive any such termination. You agree that if your use of the Site
          is terminated pursuant to these Terms of Use, you will not attempt to use
          the Site under any name, real or assumed, and further agree that if you
          violate this restriction after being terminated, you will indemnify and hold
          HelloMD harmless from any and all liability that HelloMD may incur
          therefore.
        </p>
        <p>
          The following sections will survive any termination of these Terms of Use:
          "Definitions", "Your use of the Site" (excluding the rights granted to you
          in that Section) "Intellectual Property" (excluding the rights granted to
          you in that Section), "Information and Materials","Third Party
          Interactions", "Indemnification," "Termination," "Disclaimer," "Limitation
          of Liability," "Arbitration and Governing Law," and "General".
        </p>
        <h2>General</h2>
        <p>
          These Terms of Use represent the complete agreement and understanding
          between you and HelloMD and supersede all prior agreements and
          representations between the parties with respect to the subject matter of
          these Terms of Use. These Terms of Use do not, and shall not be construed
          to, create any partnership, joint venture, employer-employee, agency, or
          franchisor-franchisee relationship between you and HelloMD. Headings used in
          these Terms of Use are for reference purposes only and in no way define or
          limit the scope of the section. If any provision of these Terms of Use is
          held to be unenforceable for any reason, such provision will be reformed
          only to the extent necessary to make it enforceable and the other terms of
          these Terms of Use will remain in full force and effect. The failure of
          HelloMD to act with respect to a breach of these Terms of Use by you or
          others does not constitute a waiver and will not limit HelloMD's rights with
          respect to such breach or any subsequent breaches. You may not assign,
          transfer, or sublicense any or all of your rights or obligations under these
          Terms of Use without our express prior written consent. We may assign,
          transfer, or sublicense any or all of our rights or obligations under these
          Terms of Use without restriction. Any use of the term "including" or
          variations thereof in these Terms of Use shall be construed as if followed
          by the phrase "without limitation." Notices to you (including notices of
          changes to this these Terms of Use) may be made via posting to the HelloMD
          Sites or by e-mail (including in each case via links), or by regular mail.
          When you visit the HelloMD Sites or send emails to us, you are communicating
          with us electronically. You consent to receive communications from us
          electronically. We will communicate with you by email or by posting notices
          on the HelloMD Sites. You agree that all agreements, notices, disclosures
          and other communications that we provide to you electronically satisfy any
          legal requirement that such communications be in writing. You further agree
          that any notices provided by us electronically are deemed to be given and
          received on the date we transmit any such electronic communication as
          described in these Terms of Use. Without limitation, a printed version of
          these Terms of Use and of any notice given in electronic form shall be
          admissible in judicial or administrative proceedings based upon or relating
          to these Terms of Use to the same extent and subject to the same conditions
          as other business documents and records originally generated and maintained
          in printed form.
        </p>
        <p>Contact Us</p>
        <p>
          If you have any questions or comments, please contact us at <a
            href="mailto:info@hellomd.com"
          >info@hellomd.com</a> or by mail at the following address: HelloMD 1400-340
          Albert Street, Ottawa, Ontario, K1R 0A5
        </p>
        <p>HelloMD Corporation © 2019. All Rights Reserved.</p>
      </Content>
    </MainLayout>
  )
}

export default TermsOfUsePage
